/**
 * Cookie Banner
 */

class BMW_CookieBanner {
  constructor() {
    this.config = {
      debugMode: true,
      userConsents: {
        all: false
      },
      groups: [
        'all'
      ]
    };

    this.UI = {
      basicWindow: $('.bmw-cn-info'),
      buttons: {
        basicAcceptAll: $('.bmw-cn-info-button-accept-all')
      }
    };

    this.initPublicAPI();

    if (this.UI.basicWindow.length) {
      this.initEvents();
      this.checkConfig();
    } else {
      let config = Cookies.get('bmw-cn-config'); 
      
      if (config) {
        this.loadConfig(config);
      }
    }
  }

  checkConfig () {
    let config = Cookies.get('bmw-cn-config');

    // When no config - show popup
    if (!config) {
      if (this.config.debugMode) {
        console.log('⛔️ Consent config not found - showing popup');
      }

      this.showPopup();
      return;
    }

    // otherwise - load config without showing popup
    this.loadConfig(config);
  }

  loadConfig (configFromCookie) {
    this.config.userConsents.all = configFromCookie.indexOf('all') > -1;

    if (this.config.debugMode) {
      console.log('⚙️ Founded config:', JSON.parse(JSON.stringify(this.config.userConsents)));
    }
  }

  initEvents () {
    this.UI.buttons.basicAcceptAll.on('click', e => {
      e.preventDefault();
      this.giveConsentForAll();
      this.closePopup();
    });
  }

  initPublicAPI () {
    window.bmwCookieBanner = {
      giveConsentAndSave: (type) => this.giveConsentAndSave(type),
      giveConsentAndSaveAndLoad: (type) => this.giveConsentAndSave(type, true),
      rejectConsentAndSave: (type) => this.rejectConsent(type),
      getConsentStatus: (type) => this.getConsentStatus(type)
    };
  }

  showPopup () {
    this.UI.basicWindow.removeClass('is-hidden');
  }

  closePopup () {
    this.UI.basicWindow.addClass('is-hidden');
  }

  giveConsentForAll () {
    this.giveConsent('all');
    this.saveConsentConfigInCookie();
  }

  giveConsent (type, forceOnLoad = false) {
    if (this.config.debugMode) {
      console.log('✅ User gave consent:', type);
    }

    this.config.userConsents[type] = true;

    if (type === 'all') {
      this.allowAll();
      return;
    }
  }

  rejectConsent (type) {
    if (this.config.debugMode) {
      console.log('✅ User reject consent:', type);
    }

    this.config.userConsents[type] = false;
    this.saveConsent();
  }

  giveConsentAndSave (type, forceOnLoad = false) {
    this.giveConsent(type, forceOnLoad);
    this.saveConsent();
  }

  saveConsent () {
    let consentCompare = this.compareConsents();
    
    // if some consent has been removed
    if (consentCompare === 'some-rejected') {
      this.saveConsentConfigInCookie();
      window.location.reload();
      return;
    }

    // otherwise - if some consent has been added
    this.saveConsentConfigInCookie();
  }

  saveConsentConfigInCookie () {
    let configToSave = [];

    for (let i = 0; i < this.config.groups.length; i++) {
      let group = this.config.groups[i];

      if (this.config.userConsents[group]) {
        configToSave.push(group);
      }
    }

    if (configToSave.length) {
      Cookies.set('bmw-cn-config', configToSave.join(';'), { expires: 180 });

      if (this.config.debugMode) {
        console.log('🍪 Consent config saved in the cookie');
      }
    } else {
      this.resetCookieConfig();
    }
  }

  resetCookieConfig () {
    Cookies.set('bmw-cn-config', 'null', { expires: 180 });

    if (this.config.debugMode) {
      console.log('🗑 User rejected all cookies - save empty config cookie');
    }
  }

  compareConsents () {
    let someRejected = false;

    for (let i = 0; i < this.config.groups.length; i++) {
      let group = this.config.groups[i];

      if (!this.config.userConsents[group]) {
        someRejected = true;
      }
    }

    if (someRejected) {
      if (this.config.debugMode) {
        console.log('🔄 Some consents has been rejected - website reload needed');
      }

      return 'some-rejected';
    }

    if (this.config.debugMode) {
      console.log('ℹ️ User not rejected any consents - website reload not needed');
    }

    return 'some-added-or-not-changed';
  }

  getConsentStatus (type) {
    return !!this.config.userConsents[type];
  }

  allowAll () {
    if (window.bmwUnlocked.indexOf('all') > -1) {
      return;
    }

    window.bmwUnlockConsentedScript('all', 'head', true);
  }
}