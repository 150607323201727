/**
 * Contest Form script
 */
 class BMW_ContestForm {
  constructor() {
    this.initState();
  }

  initState() {
    this.contestFormWrapper = $('.js-contest-form');

    if (this.contestFormWrapper.length) {
      this.initContestForm();
    }
  }

  initContestForm () {
    this.submitForm = this.contestFormWrapper.find('input[type="submit"]');
    this.contestForm = this.contestFormWrapper.find('.contest-form');

    let formHelper = new BMW_FormHelper(this.contestForm);

    $('#picture-to-upload').on('change', function(e) {
      let thisInputFile = $(e.target);
      const size = (this.files[0].size / 1024 / 1024).toFixed(2);

      if (size > 5) {
        alert('Plik nie może ważyć więcej niż 5 MB');
        thisInputFile.val('');
      }
    });

    $('#sound-to-upload').on('change', function(e) {
      let thisInputFile = $(e.target);
      const size = (this.files[0].size / 1024 / 1024).toFixed(2);

      if (size > 15) {
        alert('Plik nie może ważyć więcej niż 15 MB');
        thisInputFile.val('');
      }
    });

    $('#move-to-upload').on('change', function(e) {
      let thisInputFile = $(e.target);
      const size = (this.files[0].size / 1024 / 1024).toFixed(2);

      if (size > 15) {
        alert('Plik nie może ważyć więcej niż 15 MB');
        thisInputFile.val('');
      }
    });

    this.submitForm.on('click', e => {
      e.preventDefault();
			let result = formHelper.formValidate();

			if (result) {
        this.contestForm.addClass('is-loading');

        setTimeout(() => {
            let form = this.contestFormWrapper.find('.contest-form');
            let categoryEvent = $('input[name="data[contest_type]"]').attr('value');
            form.find('.form-contest-success').addClass('is-hidden');
            form.find('.form-contest-fail').addClass('is-hidden');

            $.ajax({
              type: 'POST',
              url: form.attr('action'),
              processData: false,
              contentType: false,
              data: new FormData(form[0]),
            }).done((data) => {
              if (data.success) {
                form.find('.form-contest-success').removeClass('is-hidden');
                form.find('input[type="submit"]').addClass('is-hidden');
                
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                  'event': 'formSent',
                  'category': categoryEvent
                });
              } else if (data.errors) {
                let output = [];
                let errorFields = Object.keys(data.errors);

                for (let i = 0; i < errorFields.length; i++) {
                  let field = errorFields[i];
                  if (data.errors[field].length) {
                    output.push(data.errors[field][0]);
                  }
                }

                output = output.join("<br />");

                form.find('.form-contest-error span').html(output);
                form.find('.form-contest-error').removeClass('is-hidden');
              } else {
                form.find('.form-contest-fail').removeClass('is-hidden');
              }
            }).fail((data) => {
              if (data.responseJSON && data.responseJSON.errors) {
                let output = [];
                let errorFields = Object.keys(data.responseJSON.errors);

                for (let i = 0; i < errorFields.length; i++) {
                  let field = errorFields[i];
                  if (data.responseJSON.errors[field].length) {
                    output.push(data.responseJSON.errors[field][0]);
                  }
                }

                output = output.join("<br />");
                form.find('.form-contest-error span').html(output);
                form.find('.form-contest-error').removeClass('is-hidden');
              } else {
                form.find('.form-contest-fail').removeClass('is-hidden');
              }
            }).always(() => {
              this.contestForm.removeClass('is-loading');
            });
        }, 1000);
			}
    });
  }
}
