/**
 * Tabs script
 */
class BMW_Tabs {
  constructor() {
    this.initState();
    this.initEvents();
  }

  initState() {
    this.tablinksWrapper = document.querySelectorAll('.tabs__tablinks');
  }

  initEvents() {
    if (!this.tablinksWrapper) {
      return;
    }

    for (let i = 0; i < this.tablinksWrapper.length; i++) {
      if (!this.tablinksWrapper[i].getAttribute('data-js-initialized')) {
        this.tablinksWrapper[i].setAttribute('data-js-initialized', true);
        let tablinks = this.tablinksWrapper[i].querySelectorAll('.tabs__tablinks-item');

        for (let j = 0; j < tablinks.length; j++) {
          tablinks[j].addEventListener('click', this.showTab.bind(this));
        }
      }
    }
  }

  showTab(e) {
    let tabID = e.target.getAttribute('data-id');
    let wrapper = e.target.parentNode.parentNode;
    let wrapperID = e.target.parentNode.parentNode.getAttribute('data-tablinks');
    let tabContent = wrapper.querySelector('.tabs__content[data-id="' + tabID + '"]');
    let tabs = wrapper.querySelectorAll('.tabs__tablinks-item');
    let content = wrapper.querySelectorAll('.tabs__content');

    if (tabID) {
      tabID = tabID.replace(/[^a-z0-9\-]/gmi, '');
    }

    if (wrapperID) {
      wrapperID = wrapperID.replace(/[^a-z0-9\-]/gmi, '');
    }

    for (let i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove('tabs__tablinks-item--active');
      content[i].classList.remove('tabs__content--showing');
      content[i].classList.remove('tabs__content--active');
      content[i].setAttribute('data-current-item', '0');
      if (content[i].querySelector('.product-slider__list')) {
        content[i].querySelector('.product-slider__list').style.marginLeft = '0%';
      }
      if (content[i].querySelector('.prev')) {
        content[i].querySelector('.prev').classList.add('is-disabled');
      }
    }

    e.target.classList.add('tabs__tablinks-item--active');
    tabContent.classList.add('tabs__content--active');

    setTimeout(() => {
      tabContent.classList.add('tabs__content--showing');
    }, 200);
  }
}