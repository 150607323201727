/**
 * Menu script
 */
class BMW_Menu {
  constructor() {
    this.initState();
    this.initEvents();
  }

  initState() {
    this.win = $(window);
    this.menuWrapper = $('.page-header');
    this.menuIcon = this.menuWrapper.find('.menu-toggle');
    this.menu = this.menuWrapper.find('.menu');
    this.menuItem = this.menu.find('.menu-item');
  }

  initEvents() {
    if (this.menuIcon.length) {
      this.menuIcon.on('click', e => {
        e.preventDefault();
        this.menu.toggleClass('is-active');
        this.menuIcon.toggleClass('is-active');
        this.menu.find('.menu-item').first().find('.menu-item-link').focus();
        $(document.body).toggleClass('no-scroll');

        setTimeout(() => {
          this.menu.toggleClass('is-showing');
        }, 150);
      });
    }

    if (this.menuItem.length) {
      this.menuItem.on('click', e => {
        this.menu.removeClass('is-active');
        this.menu.removeClass('is-showing');
        this.menuIcon.removeClass('is-active');
        $(document.body).removeClass('no-scroll');
      });
    }
  }
}
