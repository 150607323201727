/**
 * UI elements
 */
class BMW_UI {
  constructor() {
    this.initSmoothScroll();
    this.initOpenTabs();
    this.initYTVideoWithCover();
    this.initVideoWithCover();
    this.initConfirmationPopup();
    this.initExpandText();
    this.initCheckAllCookies();
  }

  initSmoothScroll () {
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - 50
          }, 1000);
        }
      }
    });
  }

  initOpenTabs () {
    let menuOpenContest = $('a[data-id="contest"]');
    let menuOpenMasterclass = $('a[data-id="masterclass"]')
    let openContest = $('.show-contest');
    let openMasterclass = $('.show-masterclass');
    let tabs = $('.tabs');
    let tablinksContest = $('.tabs__tablinks-contest');
    let tablinksMasterclass = $('.tabs__tablinks-masterclass');
    let tabcontentContest = $('.tabs__content-contest');
    let tabcontentMasterclass = $('.tabs__content-masterclass');

    openContest.on('click', e => {
      e.preventDefault();
      $('.choose-section').css('display', 'none');
      tabs.addClass('is-active');
      tablinksContest.addClass('tabs__tablinks-item--active');
      tabcontentContest.addClass('tabs__content--active');

      tablinksMasterclass.removeClass('tabs__tablinks-item--active');
      tabcontentMasterclass.removeClass('tabs__content--active');

      setTimeout(() => {
        tabcontentContest.addClass('tabs__content--showing');
        tabcontentMasterclass.removeClass('tabs__content--showing');
      }, 200);
    });

    menuOpenContest.on('click', () => {
      $('.choose-section').css('display', 'none');
      tabs.addClass('is-active');
      tablinksContest.addClass('tabs__tablinks-item--active');
      tabcontentContest.addClass('tabs__content--active');

      tablinksMasterclass.removeClass('tabs__tablinks-item--active');
      tabcontentMasterclass.removeClass('tabs__content--active');

      setTimeout(() => {
        tabcontentContest.addClass('tabs__content--showing');
        tabcontentMasterclass.removeClass('tabs__content--showing');
      }, 200);
    });

    openMasterclass.on('click', e => {
      e.preventDefault();
      $('.choose-section').css('display', 'none');
      tabs.addClass('is-active');
      tablinksMasterclass.addClass('tabs__tablinks-item--active');
      tabcontentMasterclass.addClass('tabs__content--active');

      tablinksContest.removeClass('tabs__tablinks-item--active');
      tabcontentContest.removeClass('tabs__content--active');

      setTimeout(() => {
        tabcontentMasterclass.addClass('tabs__content--showing');
        tabcontentContest.removeClass('tabs__content--showing');
      }, 200);
    });

    menuOpenMasterclass.on('click', () => {
      $('.choose-section').css('display', 'none');
      tabs.addClass('is-active');
      tablinksMasterclass.addClass('tabs__tablinks-item--active');
      tabcontentMasterclass.addClass('tabs__content--active');

      tablinksContest.removeClass('tabs__tablinks-item--active');
      tabcontentContest.removeClass('tabs__content--active');

      setTimeout(() => {
        tabcontentMasterclass.addClass('tabs__content--showing');
        tabcontentContest.removeClass('tabs__content--showing');
      }, 200);
    });

    if(window.location.hash == "#contest-block") {
      $('.choose-section').css('display', 'none');
      tabs.addClass('is-active');
      tablinksContest.addClass('tabs__tablinks-item--active');
      tabcontentContest.addClass('tabs__content--active');

      tablinksMasterclass.removeClass('tabs__tablinks-item--active');
      tabcontentMasterclass.removeClass('tabs__content--active');

      setTimeout(() => {
        tabcontentContest.addClass('tabs__content--showing');
        tabcontentMasterclass.removeClass('tabs__content--showing');
      }, 200);
    }

    if(window.location.hash == "#masterclass-block") {
      $('.choose-section').css('display', 'none');
      tabs.addClass('is-active');
      tablinksMasterclass.addClass('tabs__tablinks-item--active');
      tabcontentMasterclass.addClass('tabs__content--active');

      tablinksContest.removeClass('tabs__tablinks-item--active');
      tabcontentContest.removeClass('tabs__content--active');

      setTimeout(() => {
        tabcontentMasterclass.addClass('tabs__content--showing');
        tabcontentContest.removeClass('tabs__content--showing');
      }, 200);
    };
  }

  initYTVideoWithCover () {
    let videos = $('.video-wrapper .overlay');

    if (!videos.length) {
      return;
    }

    for (let i = 0; i < videos.length; i++) {
      let overlay = videos.eq(i);
      let downoladBtn = videos.eq(i).find('.btn');

      downoladBtn.on('click', e => {
        e.stopPropagation();
      });

      videos.eq(i).on('click', e => {
        e.preventDefault();
        overlay.addClass('is-hidden');
        let iframe = overlay.parent().find('iframe');
        
        iframe.on('load', function () {
          setTimeout(() => {
            iframe[0].contentWindow.postMessage(JSON.stringify({
              'event': 'command',
              'func': 'playVideo',
              'args': []
            }), '*');
          }, 500);
        });

        iframe.attr('src', iframe.attr('data-lazyload-src'));
      });
    }
  }

  initVideoWithCover () {
    let videos = $('.video-wrapper .overlay.cover');
    
    if (!videos.length) {
      return;
    }

    for (let i = 0; i < videos.length; i++) {
      let overlay = videos.eq(i);

      overlay.click(function() {
        overlay.addClass('is-hidden');
        let video = overlay.parent().find('.video');
        video[0].play();
      });
    }
  }

  initConfirmationPopup () {
    if (!window.location.search || window.location.search.indexOf('popup_confirmed=') === -1) {
      return;
    }

    var urlQuery = window.location.search;
    urlQuery = urlQuery.replace('?', '');
    urlQuery = urlQuery.split('&');

    for (var i = 0; i < urlQuery.length; i++) {
      var keyPair = urlQuery[i].split('=');

      if (keyPair[0] === 'popup_confirmed') {
        var popupType = parseInt(keyPair[1], 10);

        if (popupType === 1 || popupType === 2 || popupType === 0) {
          showPopup(popupType);
          break;
        }
      }
    }

    function showPopup (popupType) {
      var popup = $('<div class="confirm-popup"><div class="confirm-popup-overlay"></div><div class="confirm-popup__inner"><a href="#close" class="confirm-popup-close">&times;</a><p class="confirm-popup-content-text"></p></div></div>');
      
      if (popupType === 1) {
        popup.find('.confirm-popup-content-text').text('Dziękujemy, twoje zgłoszenie zostało potwierdzone. Powodzenia!');
      } else if (popupType === 0) {
        popup.find('.confirm-popup-content-text').text('Wystąpił problem z potwierdzeniem zgłoszenia. Spróbuj póżniej lub skontaktuj sie z nami".');
      } else if (popupType === 2) {
        popup.find('.confirm-popup-content-text').text('Dziękujemy, twoje zgłoszenie już zostało potwierdzone.');
      }

      popup.appendTo(document.body);
      popup.find('.confirm-popup-close').on('click', function (e) {
        e.preventDefault();
        popup.remove();
      });
    }
  }

  initExpandText () {
    let expandText = document.querySelectorAll('.expand-text');

    for (let i = 0; i < expandText.length; i++) {
      if (!expandText[i].getAttribute('data-js-initialized')) {
        expandText[i].setAttribute('data-js-initialized', true);

        let expandLink = expandText[i].querySelector('.expand-text__link');
        let expandTextContent = expandText[i].querySelector('.expand-text__inner');
        let expandTextContentText = expandText[i].querySelector('.expand-text__inner > div');

        if (expandLink) {
          expandTextContent.style.height = 0 + 'px';
          expandLink.classList.remove('expand-text__link--open');

          expandLink.addEventListener('click', (e) => {
            e.preventDefault();
            expandTextContent.classList.toggle('expand-text__inner--expanded');
            let newHeight = expandTextContentText.offsetHeight;

            if (expandTextContent.classList.contains('expand-text__inner--expanded')) {
              expandTextContent.style.height = 0 + 'px';
              expandLink.classList.remove('expand-text__link--open');
              expandLink.innerText = expandLink.getAttribute('data-text-less');

              setTimeout(() => {
                expandTextContent.style.height = (newHeight + 'px');
                expandLink.classList.add('expand-text__link--open');
              }, 0);

              setTimeout(() => {
                expandTextContent.style.height = 'auto';
              }, 500);
            } else {
              expandTextContent.style.height = (newHeight + 'px');
              expandLink.classList.add('expand-text__link--open');
              expandLink.innerText = expandLink.getAttribute('data-text-more');

              setTimeout(() => {
                expandTextContent.style.height = 0 + 'px';
                expandLink.classList.remove('expand-text__link--open');
              }, 0);
            }
          });
        }
      }
    }
  }

  initCheckAllCookies () {
    let checkAllCookiesBtn = $('#check-all-cookies');

    if (!checkAllCookiesBtn.length) {
      return;
    }

    let inputAll = $('.bmw-cn-consent-checkbox[data-consent-type="all"]');
    let badgebox = checkAllCookiesBtn.parents('.block-bg-gray').find('.badgebox');

    if (window.bmwCookieBanner.getConsentStatus('all')) {
      inputAll.prop('checked', true);
      badgebox.css('display', 'flex');
      checkAllCookiesBtn.css('display', 'none');
    }

    checkAllCookiesBtn.on('click', (e) => {
      e.preventDefault();
      inputAll.trigger('click');
    });

    inputAll.on('input', () => {
      if(inputAll.is(':checked')) {
        badgebox.css('display', 'flex');
        checkAllCookiesBtn.css('display', 'none');
        window.bmwCookieBanner.giveConsentAndSaveAndLoad('all');
      } else {
        checkAllCookiesBtn.css('display', 'block');
        badgebox.css('display', 'none');
        window.bmwCookieBanner.rejectConsentAndSave('all');
      }
    });
  }
}