/**
 * Vote Form script
 */
 class BMW_VoteForm {
  constructor() {
    this.initState();
    this.initAudioPlayers();
  }

  initState() {
    if (!$('.vote-form').length) {
      return
    }

    $('.vote-forms-wrapper').on('click', e => {
      if (e.target.tagName === 'INPUT' && e.target.getAttribute('class').indexOf('btn-vote-form-submit') > -1) {
        e.preventDefault();
        let formWrapper = $(e.target.parentNode);
        this.submitForm(formWrapper);
      }
    });

    this.loadMoreBtn = $('#load-more-items');
    this.itemsList = $('.vote-forms-list');

    if (this.loadMoreBtn.length) {
      this.loadMoreBtn.on('click', e => {
        e.preventDefault();
        this.loadMoreItems();
      });
    }
  }

  initAudioPlayers () {
    if (!$('.audio-player').length) {
      return;
    }

    GreenAudioPlayer.init({
      selector: '.audio-player:not([data-green-audio-player-is-initialized="true"])',
      stopOthersOnPlay: true
    });

    $('.audio-player:not([data-green-audio-player-is-initialized="true"])').each((i, playerInstance) => {
      $(playerInstance).attr('data-green-audio-player-is-initialized', 'true');
    });
  }

  submitForm (wrapper) {
    wrapper.addClass('is-loading');

    setTimeout(() => {
      wrapper.find('.form-contest-success').addClass('is-hidden');
      wrapper.find('.form-contest-fail').addClass('is-hidden');
      wrapper.find('.form-contest-error').addClass('is-hidden');

      $.ajax({
        type: 'POST',
        url: wrapper.attr('action'),
        processData: false,
        contentType: false,
        data: new FormData(wrapper[0]),
      }).done((data) => {
        if (data.success) {
          wrapper.find('.form-contest-success').removeClass('is-hidden');
          wrapper.find('input[type="submit"]').addClass('is-hidden');
        } else if (data.error) {
          wrapper.find('.form-contest-error span').text(data.responseJSON.error);
          wrapper.find('.form-contest-error').removeClass('is-hidden');
          wrapper.find('input[type="submit"]').addClass('is-hidden');
        } else {
          wrapper.find('.form-contest-fail').removeClass('is-hidden');
          wrapper.find('input[type="submit"]').addClass('is-hidden')
        }
      }).fail((data) => {
        if (data.responseJSON && data.responseJSON.error) {
          wrapper.find('.form-contest-error span').text(data.responseJSON.error);
          wrapper.find('.form-contest-error').removeClass('is-hidden');
          wrapper.find('input[type="submit"]').addClass('is-hidden');
        } else {
          wrapper.find('.form-contest-fail').removeClass('is-hidden');
          wrapper.find('input[type="submit"]').addClass('is-hidden')
        }
      }).always(() => {
        wrapper.removeClass('is-loading');
      });
    }, 1000);
  }

  loadMoreItems () {
    this.loadMoreBtn.addClass('is-loading');
    let initialOffset = parseInt(this.loadMoreBtn.attr('data-initial-offset'), 10);
    let currentPage = parseInt(this.loadMoreBtn.attr('data-current-page'), 10);
    let itemsPerPage = 12;
    let offset = initialOffset + ((currentPage - 1) * itemsPerPage);
    let maxItems = parseInt(this.loadMoreBtn.attr('data-count'), 10);
    let contestType = this.loadMoreBtn.attr('data-contest-type');

    if (offset > maxItems) {
      return false;
    }

    $.ajax({
      type: 'POST',
      url: this.loadMoreBtn.attr('data-endpoint'),
      data: {
        _token: $('meta[name="_token"]').attr('content'),
        contest_id: parseInt(this.loadMoreBtn.attr('data-contest-id'), 10),
        offset: offset
      }
    }).done((data) => {
      currentPage++;
      this.loadMoreBtn.attr('data-current-page', currentPage);

      if (data.end) {
        this.loadMoreBtn.remove();
      }

      if (data.items && data.items.length) {
        for (let i = 0; i < data.items.length; i++) {
          this.renderItem(data.items[i], contestType);
        }
      }

      setTimeout(() => {
        this.initAudioPlayers();
      }, 0);
    }).always(() => {
      this.loadMoreBtn.removeClass('is-loading');
    });
  }

  renderItem (itemData, type) {
    let template = '';

    if (type === 'picture') {
      template = `
      <form
        id="submission-${parseInt(itemData.id, 10)}"
        class="vote-form picture"
        method="post"
        action="${itemData.voteUrl}">
        <input type="hidden" name="_token" value="${itemData.token}">
        <input type="hidden" name="submission_id" value="${parseInt(itemData.id, 10)}" required>
        <span class="submission-title">${itemData.name}</span>
        <div class="image">
          <img src="${itemData.itemUrl}" alt="" loading="lazy">
        </div>
        <input type="submit" value="Zagłosuj" class="btn btn-dark btn-vote-form-submit">
        <button type="button" onclick="copyLink('link-to-${itemData.id}')" class="btn btn-dark btn-copy">Skopiuj link</button>
        <p id="link-to-${itemData.id}-copied" class="link-copied" style="display: none;">Link został skopiowany!</p>
        <p id="link-to-${itemData.id}" style="display: none">https://bmwkreatywnejazdy.pl/konkurs/zgloszenie/${itemData.id}</p>
        <div class="form-contest-success is-hidden">
            Zagłosowano!
        </div>
        <div class="form-contest-fail is-hidden">
            Wystąpił błąd
        </div>
        <div class="form-contest-error is-hidden">
            Nie możesz głosować ponownie
        </div>
      </form>
      `;
    } else if (type === 'move') {
      template = `
      <form
        id="submission-${parseInt(itemData.id, 10)}"
        class="vote-form move"
        method="post"
        action="${itemData.voteUrl}">
        <input type="hidden" name="_token" value="${itemData.token}">
        <input type="hidden" name="submission_id" value="${parseInt(itemData.id, 10)}" required>
        <span class="submission-title">${itemData.name}</span>
        <div class="movie">
            <video width="80%" height="300" controls>
              <source src="${itemData.itemUrl}">
            </video>
        </div>
        <input type="submit" value="Zagłosuj" class="btn btn-dark btn-vote-form-submit">
        <button type="button" onclick="copyLink('link-to-${itemData.id}')" class="btn btn-dark btn-copy">Skopiuj link</button>
        <p id="link-to-${itemData.id}-copied" class="link-copied" style="display: none;">Link został skopiowany!</p>
        <p id="link-to-${itemData.id}" style="display: none">https://bmwkreatywnejazdy.pl/konkurs/zgloszenie/${itemData.id}</p>
        <div class="form-contest-success is-hidden">
          Zagłosowano!
        </div>
        <div class="form-contest-fail is-hidden">
          Wystąpił błąd
        </div>
        <div class="form-contest-error is-hidden">
          Nie możesz głosować ponownie
        </div>
      </form>
      `;
    } else if (type === 'sound') {
      template = `
      <form
        id="submission-${parseInt(itemData.id, 10)}"
        class="vote-form sound"
        method="post"
        action="${itemData.voteUrl}">
        <input type="hidden" name="_token" value="${itemData.token}">
        <input type="hidden" name="submission_id" value="${parseInt(itemData.id, 10)}" required>
        <div class="audio">
            <span class="submission-title">${itemData.name}</span>
            <div class="audio-player">
              <audio>
                <source src="${itemData.itemUrl}">
                Your browser does not support the audio element.
              </audio>
            </div>
        </div>
        <input type="submit" value="Zagłosuj" class="btn btn-dark btn-vote-form-submit">
        <button type="button" onclick="copyLink('link-to-${itemData.id}')" class="btn btn-dark btn-copy">Skopiuj link</button>
        <p id="link-to-${itemData.id}-copied" class="link-copied" style="display: none;">Link został skopiowany!</p>
        <p id="link-to-${itemData.id}" style="display: none">https://bmwkreatywnejazdy.pl/konkurs/zgloszenie/${itemData.id}</p>
        <div class="form-contest-success is-hidden">
          Zagłosowano!
        </div>
        <div class="form-contest-fail is-hidden">
          Wystąpił błąd
        </div>
        <div class="form-contest-error is-hidden">
          Nie możesz głosować ponownie
        </div>
      </form>
      `;
    }

    if (template) {
      $(template).appendTo(this.itemsList);
    }
  }
}
